@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,600,700|Oswald:400,300,700);
/* Import */
/* Variables */
/* Base */
body {
  background: #252827;
  font-size: 16px;
}
strong {
  font-weight: 600;
}
h1 {
  font-family: 'Oswald', sans-serif;
  letter-spacing: 1.5px;
  color: #ffffff;
  font-weight: 100;
  font-size: 2.4em;
}
#content {
  /* margin-top: 50px; */
  text-align: center;
}
/* Timeline */
.timeline {
  /* border-bottom-right-radius: 4px; */
  /* border-top-right-radius: 4px; */
  /* background: rgba(255, 255, 255, 0.03); */
  /* color: rgba(255, 255, 255, 0.8); */
  /* font-family: 'Source Sans Pro', sans-serif; */
  /* margin: 50px auto; */
  letter-spacing: 0.5px;
  position: relative;
  line-height: 1.4em;
  font-size: 1.03em;
  /* padding: 50px; */
  list-style: none;
  text-align: left;
  /* font-weight: 100; */
  /* max-width: 30%; */
}
.timeline h1,
.timeline h2,
.timeline h3 {
  letter-spacing: 1.5px;
  /* font-weight: 100; */
  /* font-size: 1.4em; */
}
.timeline .event {
  border-bottom: 1px dashed rgba(255, 255, 255, 0.1);
  padding-bottom: 50px !important;
  /* margin-bottom: 30px !important; */
  position: relative;
  display: flex;
  flex-direction: column;
}
.timeline .event:last-of-type {
  padding-bottom: 0 !important;
  margin-bottom: 0;
  border: none;
}
.timeline .event:before,
.timeline .event:after {
  position: absolute;
  display: block;
  top: 4px;
}
.timeline .event:before {
  height: 100%;
  width: 1px;
  flex: 1;
  background-color: #00a1de;
  display: block;
  content: ""
}

.timeline .event.incomplete-event:before {
  height: 100%;
  width: 1px;
  flex: 1;
  background-color: #777;
  display: block;
  content: ""
}

.timeline .event:last-of-type:before {
  display: none;
}

.timeline .event:after {
  left: -4px;
  border: 1px solid #00a1de;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  font-size: 33px;
  color: #00a1de;
  content: "";
  /* content: "\2219"; */
  display: flex;
  align-items: center;
  top: 4px;
  background-color: #fff;
  justify-content: center;
  padding-top: 1px;
}
.timeline .event.incomplete-event:after {
  left: -4px;
  border: 1px solid #00a1de;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  font-size: 33px;
  color: #777;
  content: "";
  /* content: "\2219"; */
  display: flex;
  align-items: center;
  top: 4px;
  background-color: #fff;
  justify-content: center;
  padding-top: 1px;
}

.timeline p, .timeline li{
  margin-left: 10px;
  margin-bottom: 0 !important;
}

.timeline .instrument-title{
  height: inherit !important;
  line-height: 1.2rem;
}
.timeline .instrument-desc{
  font-size: .8rem !important;
  color: #232323;
}

.timeline .incomplete-event .instrument-title, .timeline .incomplete-event .instrument-desc{
  color: #777;
}
.timeline .instrument-desc{
}

.timeline-title{
  color: #777 !important;
  height: inherit !important;
  line-height: 1.5rem;
  font-family:'MyriadProSemibold' !important;
	font-size:0.9rem !important
}

@media (max-width:576px){
  .timeline .event{
    padding-bottom:8px
  }
}

/* @media (max-height:625px){
  .timeline-title{
    font-size: 0.75rem !important;
  }
} */