.navbar-home{
	box-shadow: 0 0px 7px 0px rgba(0,0,0,0.16), 0 -1px 8px 0 rgba(0,0,0,0.12) !important;
}

body,.page-wrapper{
	background-color:#fcfaff !important;
}
.page-wrapper{
	height: 100vh;
	overflow: hidden;
	padding-bottom: 60px;
}

#root{
	height:100%
}

@font-face {
	font-family: 'MyriadProRegular';
	src: url('../font/MyriadPro/MYRIADPRO-REGULAR.woff');
}

@font-face {
	font-family: 'MyriadProSemibold';
	src: url('../font/MyriadPro/MYRIADPRO-SEMIBOLD.woff');
}
@font-face {
	font-family: 'RwWidgets';
	src: url('../../../node_modules/react-widgets/lib/fonts/rw-widgets.woff');
}

a,p,span,input,textarea{
	font-family: 'MyriadProRegular' !important
}

h1,h2,h3,h4,h5,h6{
	font-family: 'MyriadProSemibold' !important
}

html,body,#app,#wrapper, #hero_register{
	  /* height: 100% */
}

.landing-wrap{
	background-image: url('../img/landingBg.jpg');
}
.landing-wrap h1{
	font-family:'Advent Pro' !important;
}

.new-order-div h2{
	font-size:1.8rem !important;
	height: initial;
	color: #0055a6;
	font-family: 'MyriadProSemibold' !important;
}

.new-order-div .h2-span{
	color: #0055a6 !important;
	font-size:1.1rem !important;
	font-weight:bold !important;
}
.new-order-div .intro-desc{
	color: #777;
	font-family: 'MyriadProRegular';
}

.ReactGridGallery_tile-viewport{
	margin: 0 !important;
}
.rangeslider{
	margin: 2px 0 10px 0 !important;
}
.rangeslider-horizontal{
	height: 7px !important;
}
.rangeslider-horizontal .rangeslider__handle:after{
	display: none !important;
}
.rangeslider-horizontal .rangeslider__fill{
	background-color: #0055a6 !important;
}
.rangeslider-horizontal .rangeslider__handle{
	width: 15px !important;
	height: 15px !important;
	border-radius: 50% !important;
}

.menu-button {
    width: 20px;
    height: 1px;
    background: #0e3c89;
    margin: 15px 0 0 15px;
}

.menu-button:before {
    display: block;
    content: "";
    width: 25px;
	height: 2px;
	border-radius: 4px;
    box-shadow: 0 -6px 0 0 #0e3c89, 0 6px 0 0 #0e3c89;
}

/*Clinic registration map css*/
#map {
	height: 300px;
	width: 90%;
	margin: 0 auto
  }
  /* Optional: Makes the sample page fill the window. */
  html, body {
	height: 100%;
	margin: 0;
	padding: 0;
  }
  .controls {
	background-color: #fff;
	border-radius: 2px;
	border: 1px solid transparent;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
	box-sizing: border-box;
	font-family: Roboto;
	font-size: 15px;
	font-weight: 300;
	height: 29px;
	margin-left: 17px;
	margin-top: 10px;
	outline: none;
	padding: 0 11px 0 13px;
	text-overflow: ellipsis;
	width: 400px;
  }
  
  .controls:focus {
	border-color: #4d90fe;
  }
  .title {
	font-weight: bold;
  }
  #infowindow-content {
	display: none;
  }
  #map #infowindow-content {
	display: inline;
  }
  

.table-head{
	background-color: #0055a6;
	color: #fff;
	font-weight: normal;
	border-bottom: 3px solid #1d4b9d
}
.table-head th{
	font-weight: normal !important
}

tbody{
	border-bottom: 1px solid #1d4b9d;
}

.common-td{
	border-width: 1px 1px 0px 1px !important;
	border: solid #dee2e6;
}

.testimonials-div{
	border-left: 1.5px solid #027ade;
	border-radius: 2.5px;
}
.side-border{
	border-right: 3px solid #027ade;
	padding-right: 10px;
}
.w-0{
	width: 0 !important;
}
.w-10 {
	width: 10% !important;
}
.w-20 {
	width: 20% !important;
}
.w-30 {
	width: 30% !important;
}
.w-40 {
	width: 40% !important;
}
.w-50 {
	width: 50% !important;
}
.w-60 {
	width: 60% !important;
}
.w-65 {
	width: 140px !important;
}
.w-70 {
	width: 70% !important;
}
.w-80 {
	width: 80% !important;
}
.w-90 {
	width: 90% !important;
}
.w-100 {
	width: 100% !important;
}

.profile-picture{
	border-radius: 4px
}

.profile-picture-div{
	background-size: contain;
    height: 200px;
    width: 80%;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0 auto;
}

.close-icon{
	font-size: 21px !important;
	color: #232323;
}
.component-loading{
	height: 100%;
	overflow: auto;
	/* min-height: calc( 100vh - 60px); */
	min-height: 100vh;
	padding-top: 100px;
}

.table-striped tbody tr:nth-of-type(odd) {
	background-color: #f4f7fe;
}

body {
	font-size: 13px;
	color: #2f3245;
	/* font-family: 'Poppins', sans-serif !important; */
	letter-spacing: .02rem;
	background: #f4f7fe;
	/* font-weight: 400; */
}
input,span,p,a,td,th{
	font-size: .75rem !important;
}
input,textarea,select{
	border-radius: 3px !important
}
th{
	font-weight: bold !important
}
#page{
	background-color: #f0f0f5;
}
.inner-page{
	background-color: #f0f0f5 !important
}
.header {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
    box-shadow: none;
    background-color: #FC466B;
    position: fixed;
    height: 60px!important;
    overflow: hidden;
    z-index: 10;
}
.main {
    margin: 0 auto;
    display: block;
    height: 100%;
    margin-top: 60px;
}

.main-wrapper{
	height: 100%;
}
/*-----------------DASHBOARD SIDEBAR----------*/

.side-menu{
	padding-left: 0;
	height: calc( 100vh - 270px );
    overflow-y: auto;
    overflow-x: hidden;
}

.side-menu::-webkit-scrollbar {
	width: .4rem;
}
   
.side-menu::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	border-radius: 5px;
}
   
.side-menu::-webkit-scrollbar-thumb {
	background-color: darkgrey;
	outline: 1px solid slategrey;
	border-radius: 5px;
	opacity: .5;
}

.page-header.dashboard-header.show-drawer .main-navbar{
	margin-left: 15vw;
	transition: margin-left .5s;
}
.page-sidebar.dashboard-sidebar {
	height: 100%; /* 100% Full-height */
	width: 0px; /* 0 width - change this with JavaScript */
	position: fixed; /* Stay in place */
	z-index: 2 !important; /* Stay on top */
	top: 0; /* Stay at the top */
	left: 0;
	color:#fff;
	background-color: #0055a6; /* Black*/
	overflow: hidden; /* Disable horizontal scroll */
	padding-top: 60px; /* Place content 60px from the top */
	transition: all 0.5s; /* 0.5 second transition effect to slide in the sidenav */
	box-shadow: 0 0px 2px 1px rgba(0,0,0,0.2), 0 -1px 2px 0 rgba(0,0,0,0.12) !important;
  }
.page-sidebar.dashboard-sidebar.show-drawer {
	height: 100%; /* 100% Full-height */
	width: 15vw; /* 0 width - change this with JavaScript */
	position: fixed; /* Stay in place */
	z-index: 2; /* Stay on top */
	top: 0; /* Stay at the top */
	left: 0;
	color:#fff;
	background-color: #0055a6; /* Black*/
	overflow-y: auto; /* Disable horizontal scroll */
	padding-top: 60px; /* Place content 60px from the top */
	transition: all 0.5s; /* 0.5 second transition effect to slide in the sidenav */
	box-shadow: 0 0px 2px 1px rgba(0,0,0,0.2), 0 -1px 2px 0 rgba(0,0,0,0.12) !important;
  }

.page-sidebar.dashboard-sidebar.show-drawer::-webkit-scrollbar { 
	width: 0 !important
}
.dashboard-sidebar .side-menu__icon{
	width: 18px !important;
}  
.dashboard-sidebar .side-menu__label{
	font-size: .85rem !important;
}  

.dashboard-sidebar .side-menu__btn{
	color: #0055a6;
	border-radius: 25px !important;
	font-size: 0.7rem;
}

.page-sidebar.dashboard-sidebar.show-drawer ul,.page-sidebar.dashboard-sidebar.show-drawer li{
	width: 15vw
}

.page-sidebar.dashboard-sidebar ul,.page-sidebar.dashboard-sidebar li{
	width: 0px
}

.page-sidebar.dashboard-sidebar.show-drawer a {
	padding: 8px 8px 8px 32px;
	text-decoration: none;
	color: #fff;
	display: block;
	transition: 0.5s;
	/* border-radius:3px !important; */
  }

  .page-sidebar.dashboard-sidebar a{
	padding: 8px 8px 8px 32px;
    text-decoration: none;
	color: #fff;
	width: 215px;
    display: block;
    transition: 0.5s;
    /* border-radius: 3px !important; */
  }

  .page-sidebar.dashboard-sidebar .side-menu__item{
	width: 100%;
  }

  .page-sidebar.dashboard-sidebar.show-drawer .side-menu__item:hover, .page-sidebar.dashboard-sidebar.show-drawer .side-menu__item:focus, .page-sidebar.dashboard-sidebar.show-drawer .side-menu__item.active {
	color: #0055a6;
	background-color:#e4f5fd !important;
	width: 100%;
	/* border-left: 3px solid #fff */
  }
  
  .page-sidebar.dashboard-sidebar.show-drawer .closebtn {
	position: absolute;
	top: 0;
	right: 25px;
	font-size: 36px;
	margin-left: 50px;
  }
  .page-content.dashboard-content {
	background-color:#fcfaff;
	transition: margin-left .5s;
	padding: 20px;
	margin-top: 60px;
}  
  .page-content.dashboard-content.show-drawer {
	margin-left: 15vw;
	background-color:#fcfaff;
	transition: margin-left .5s;
	padding: 20px;
  }
  .side-menu span{
	font-weight: 500;
	font-size: 14.75px
}

.filter-label{
	color: #777;
	font-family: 'MyriadProSemibold' !important;
	font-size: 14px !important
}
.dashboard-filter__icon{
	width:18px !important;
}

.filter-dropdown::after{
	float: right;
	margin-top: 0.5em
}
.filter-dropdown{
	width: 75% !important;
}
.filter-dropdown .drop-down-item{
	color:#232323 !important
}

.dashboard-filter__btn{
	background-color: #0055a6 !important;
	border-radius: 25px !important;
}

  @media screen and (max-height: 450px) {
	.page-sidebar.dashboard-sidebar.show-drawer {padding-top: 15px;}
	.page-sidebar.dashboard-sidebar.show-drawer a {font-size: 18px;}

  }
/*-----------------DASHBOARD SIDEBAR----------*/

.rw-list-optgroup{
	border: none !important;
	color: #0055a6 !important
}

.btn_search{
	background-color: #0055a6 !important
}

.dashboard-card-span{
	font-size: 11px !important;
}

.item-wrap{
	width:100%;
	margin:auto;
	margin-top: 100px;
	max-width:370px;
	min-height:330px;
	position:relative;
	border-radius: 5%;
	background-color: #fff;
	box-shadow: 0 15px 20px 0 rgba(0,0,0,0.1) !important;
}

.item-html{
	width:100%;
	height:100%;
	/* position:absolute; */
	padding:10%;
	border-radius: 4%;
	background:#fff;
	/* box-shadow: 0 11px 18px 0 rgba(0,0,0,0.1) !important; */
}

.item-shrunk{
	min-height: 200px !important;
}

.instrument-detail-wrap{
	width:100%;
	margin:auto;
	margin-top: 100px;
	/* max-width:370px; */
	min-height:350px;
	position:relative;
	border-radius: 11px;
	background-color: #fff;
	box-shadow: 0 15px 20px 0 rgba(0,0,0,0.1) !important;
}

input[type=checkbox] {
    transition: all 0.3s;
    box-sizing: border-box;
    display: none;
}
.upload-btn-wrapper {
    overflow: hidden;
    display: inline-block;
    font-size: 12px;
    padding: 10px;
    text-align: center;
    color: #5a6270;
    border: 2px dotted #d3d3d3;
    cursor: pointer  
} 
.ReactGridGallery_tile, .ReactGridGallery_tile img, .ReactGridGallery_tile-viewport{
	height: 350px !important;
	width: 250px !important;
	border: none;
	border-radius: 3px !important;
}
.ReactGridGallery_tile{
	margin: 8px !important
}
.ReactGridGallery_tile-bottom-bar{
	/* left: 10px !important; */
	/* padding: 2px 0  !important; */
	min-width: 100%;
	background: rgba(0, 0, 0, 0.65);
}
.ReactGridGallery_tile-bottom-bar div{
	margin: 0 !important;
}
.ReactGridGallery_tile-bottom-bar span{
	padding: 10px !important;
	border-radius: 2px !important;
	font-size: 12px !important;
	letter-spacing: 0.05rem !important;
	font-weight: normal !important;
	min-width: 100%;
	color: #fff !important;
}

.rw-widget-container{
	border-radius: 2px !important;
	border-color: #e1e8ed !important
}
.rw-widget-container input{
	box-shadow: none;
	padding: 8px 15px
}
.rw-widget-input{
	box-shadow: none;
	padding: 4px 7px
}
.btn-outline-success{
	border-color:#0055a6 !important;
}
.custom-control-label {
	display: flex;
}
.btn:focus, .btn:active, .btn:active:focus, .form-control:focus {
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
	outline: none;
}
a {
	/* color: #3454f5; */
	color: #0055a6;
	font-weight: 400;
}
a:hover {
	/* color: #480e82; */
	color: #51cbce
}
a:not(.btn-social-icon):not(.btn-social):not(.page-link) .ion, a:not(.btn-social-icon):not(.btn-social):not(.page-link) .fas, a:not(.btn-social-icon):not(.btn-social):not(.page-link) .far, a:not(.btn-social-icon):not(.btn-social):not(.page-link) .fal, a:not(.btn-social-icon):not(.btn-social):not(.page-link) .fab {
	margin-left: 4px;
}
#fullscreen {
	overflow: auto !important;
	background: #fff;
}

.w-32 {
	width: 32px !important;
}

/*-- list unstyled --*/

.list-unstyled-border li {
	border-bottom: 1px solid #f0f0f2;
	padding-bottom: 15px;
	margin-bottom: 15px;
}
.visitor .media:first-child {
	margin-top: 0;
}
.visitor .media-body {
	margin-left: 0px;
	margin-top: 5px;
}
.visitor .media .media-title {
	margin-bottom: 0;
}
/*-- end list unstyled --*/

/*-- helper --*/

.text-dark {
	color: #484c67 !important;
}
.text-primary {
	color: #3454f5 !important;
}
.text-secondary {
    color: #3e1088!important;
}
.text-success {
	color: #6cb442 !important;
}
.text-danger {
	color: #fa292e !important;
}
.text-info {
	color: #01b8ff !important;
}
.text-warning {
	color: #e98300 !important;
}
.jumbotron {
	background: #f4f7fe;
}
.text-muted {
	color: rgb(124, 132, 140) !important;
}
.breadcrumb-item.active {
	/* color: #3454f5; */
	color: #01cf6b;
	color: #0055a6;
}
.breadcrumb-item+.breadcrumb-item::before {
	display: inline-block;
	padding-right: .5rem;
	color: #ab7fa5;
	content: "/";
}
.bullet, .slash {
	display: inline;
	margin: 0 4px;
}
.bullet:after {
	content: '\2022';
}
.slash:after {
	content: '/';
}
/*-- end helper --*/

/*-- section --*/
.section {
	position: relative;
	z-index: 1;
}
.section .section-header {
	border-radius: 3px;
	padding: 20px 0;
	display: inline-block;
	width: 100%;
	margin-bottom: 0;
}
.section .section-header h1 {
	display: inline-block;
	font-size: 24px;
	margin-top: 3px;
	float: left;
}
.section .section-header .float-right {
	margin-top: 0;
}
.section .section-header .btn {
	font-size: 12px;
}
.section .section-header+.section-body .section-title:first-child {
	margin-top: 0;
}
.section .section-title {
	font-size: 16px;
	color: #333;
	font-weight: 500;
	margin: 30px 0 15px 0;
}
/*-- end section --*/

/*-- layout --*/

.main-sidebar {
	position: fixed;
	top: 70px;
	left: 0;
	height: 100%;
	width: 250px;
	z-index: 880;
	background-color: #fff;
	border-right: 1px solid #f0f0f2;
}
body.sidebar-gone .main-sidebar {
	left: -250px;
}
body.sidebar-gone .navbar {
	left: 0;
}
body.sidebar-gone .main-content {
	padding-left: 30px;
}
.header-brand {
	color: inherit;
	margin-right: 1rem;
	font-size: 1.25rem;
	white-space: nowrap;
	font-weight: 600;
	padding: 0;
	transition: .3s opacity;
	line-height: 2.9rem;
}
.main-navbar .header-brand {
	color: inherit;
	margin-right: 0;
	font-size: 1.25rem;
	white-space: nowrap;
	font-weight: 600;
	padding: 0;
	transition: .3s opacity;
	line-height: 2.9rem;
	min-width: 200px;
	text-align: center;
}
.main-navbar .header-brand .header-brand-img {
	margin-right: 0;
}
.app-header1 .header-brand {
	color: inherit;
	margin-right: 0;
	font-size: 1.25rem;
	white-space: nowrap;
	font-weight: 600;
	padding: 0;
	transition: .3s opacity;
	line-height: 2.9rem;
	min-width: 215px;
	text-align: center;
}
.app-header1 .header-brand .header-brand-img {
	margin-right: 0;
}
.header-brand:hover {
	color: inherit;
	text-decoration: none;
}
.header-brand-img {
	height: 2.5rem;
	line-height: 2rem;
	vertical-align: bottom;
	margin-right: .5rem;
	width: auto;
	position: relative;
	top: -3px;
}

.sidenav-toggled .main-footer {
	padding: 20px 30px 20px 0px;
	margin-left:90px;
}
.overflow-hidden {
	overflow: hidden;
}
.progress {
	background: #f4f7fe;
	box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
}
.progress-bar {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: center;
	justify-content: center;
	color: #fff;
	text-align: center;
	white-space: nowrap;
	background-color: #3454f5;
	transition: width .6s ease;
}
.progress-bar-striped {
	background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	background-size: 1rem 1rem;
}
.progress-bar-animated {
	-webkit-animation: progress-bar-stripes 1s linear infinite;
	/* animation: progress-bar-stripes 1s linear infinite; */
}

/*---Colors---*/

.bg-gray {
	background-color: #f1f2f3 !important;
}
.bg-primary {
	background-color: #3454f5 !important;
	color: #fff !important;
}
.bg-secondary {
	background-color: #3e1088 !important;
	color: #fff !important;
}
.bg-success {
	/* background-color: #01cf6b !important; */
	background-color: #0055a6 !important;
	color: #fff !important;
}
.bg-danger {
	background-color: #fa292e !important;
	color: #fff !important;
}
.bg-info {
	background-color: #01b8ff !important;
	color: #fff !important;
}
.bg-warning {
	background-color: #fe7100 !important;
	color: #fff !important;
}
.bg-gradient {
	background: linear-gradient(#3454f5, #3e1088) !important;
}
/*-- end layout --*/

/*
 * Page Loader
 * ----------------------
 */

#spinner {
	position: fixed;
	z-index: 50000;
	background: url(../img/loader.svg) no-repeat 50% 50% rgb(255, 255, 255);
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	margin: 0 auto;
}

@media (max-width:320px){
	form .files input {
		outline-offset: 0px !important;
		padding: 120px 0px 85px 0 !important;
	}
}


/*
 * Pagination
 * ----------------------
 */

.pagination {
	display: -ms-flexbox;
	display: flex;
	padding-left: 0;
	list-style: none;
	border-radius: 3px;
}
.page-link {
	position: relative;
	display: block;
	/* padding: 0.5rem 0.75rem; */
	margin-left: -1px;
	line-height: 1.25;
	background-color: #fff;
	border: 1px solid #d7e7ff;
}
.page-link:hover {
	z-index: 2;
	color: #295a9f;
	text-decoration: none;
	background-color: rgba(70, 127, 207, 0.06);
}
.page-link:focus {
	z-index: 2;
	outline: 0;
}
.page-link:not(:disabled):not(.disabled) {
	cursor: pointer;
}
.page-item:first-child .page-link {
	margin-left: 0;
	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
}
.page-item:last-child .page-link {
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
}
.page-item.active .page-link {
	z-index: 1;
	color: #fff;
	background-color: #736cc7;
}
.page-item.disabled .page-link {
	color: #ced4da;
	pointer-events: none;
	cursor: auto;
	background-color: #fff;
	border-color: #f0f0f2;
}
.pagination-lg .page-link {
	padding: 0.75rem 1.5rem;
	font-size: 1.125rem;
	line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
}
.pagination-lg .page-item:last-child .page-link {
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
}
.pagination-sm .page-link {
	padding: 0.25rem 0.5rem;
	font-size: 0.875rem;
	line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
}
.pagination-sm .page-item:last-child .page-link {
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
}
.page-item .page-link {
	color: #3454f5;
}
.page-item.active .page-link {
	background-color: #3454f5;
	border-color: #3454f5;
}
.page-item.disabled .page-link, .page-link {
	border-color: #f0f0f2;
}
/*
 * Data Tables
 * ----------------------
 */

table.dataTable {
	clear: both;
	margin-top: 6px !important;
	margin-bottom: 6px !important;
	max-width: none !important;
	border-collapse: separate !important;
	border-spacing: 0;
}
table.dataTable td, table.dataTable th {
	-webkit-box-sizing: content-box;
	box-sizing: content-box;
}
table.dataTable td.dataTables_empty, table.dataTable th.dataTables_empty {
	text-align: center;
}
table.dataTable.nowrap th, table.dataTable.nowrap td {
	white-space: nowrap;
}
div.dataTables_wrapper div.dataTables_length label {
	font-weight: normal;
	text-align: left;
	white-space: nowrap;
}
div.dataTables_wrapper div.dataTables_length select {
	width: auto;
	display: inline-block;
}
div.dataTables_wrapper div.dataTables_filter {
	text-align: right;
}
div.dataTables_wrapper div.dataTables_filter label {
	font-weight: normal;
	white-space: nowrap;
	text-align: left;
}
div.dataTables_wrapper div.dataTables_filter input {
	margin-left: 0.5em;
	display: inline-block;
	width: auto;
}
div.dataTables_wrapper div.dataTables_info {
	padding-top: 0.85em;
	white-space: nowrap;
}
div.dataTables_wrapper div.dataTables_paginate {
	margin: 0;
	white-space: nowrap;
	text-align: right;
}
div.dataTables_wrapper div.dataTables_paginate ul.pagination {
	margin: 2px 0;
	white-space: nowrap;
	justify-content: flex-end;
}
div.dataTables_wrapper div.dataTables_processing {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 200px;
	margin-left: -100px;
	margin-top: -26px;
	text-align: center;
	padding: 1em 0;
}
table.dataTable thead>tr>th.sorting_asc, table.dataTable thead>tr>th.sorting_desc, table.dataTable thead>tr>th.sorting, table.dataTable thead>tr>td.sorting_asc, table.dataTable thead>tr>td.sorting_desc, table.dataTable thead>tr>td.sorting {
	padding-right: 30px;
}
table.dataTable thead>tr>th:active, table.dataTable thead>tr>td:active {
	outline: none;
}
table.dataTable thead .sorting, table.dataTable thead .sorting_asc, table.dataTable thead .sorting_desc, table.dataTable thead .sorting_asc_disabled, table.dataTable thead .sorting_desc_disabled {
	cursor: pointer;
	position: relative;
}
table.dataTable thead .sorting:before, table.dataTable thead .sorting:after, table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_asc:after, table.dataTable thead .sorting_desc:before, table.dataTable thead .sorting_desc:after, table.dataTable thead .sorting_asc_disabled:before, table.dataTable thead .sorting_asc_disabled:after, table.dataTable thead .sorting_desc_disabled:before, table.dataTable thead .sorting_desc_disabled:after {
	position: absolute;
	bottom: 0.9em;
	display: block;
	opacity: 0.3;
}
table.dataTable thead .sorting:before, table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_desc:before, table.dataTable thead .sorting_asc_disabled:before, table.dataTable thead .sorting_desc_disabled:before {
	right: 1em;
	content: "\2191";
}
table.dataTable thead .sorting:after, table.dataTable thead .sorting_asc:after, table.dataTable thead .sorting_desc:after, table.dataTable thead .sorting_asc_disabled:after, table.dataTable thead .sorting_desc_disabled:after {
	right: 0.5em;
	content: "\2193";
}
table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_desc:after {
	opacity: 1;
}
table.dataTable thead .sorting_asc_disabled:before, table.dataTable thead .sorting_desc_disabled:after {
	opacity: 0;
}
div.dataTables_scrollHead table.dataTable {
	margin-bottom: 0 !important;
}
div.dataTables_scrollBody table {
	border-top: none;
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}
div.dataTables_scrollBody table thead .sorting:before, div.dataTables_scrollBody table thead .sorting_asc:before, div.dataTables_scrollBody table thead .sorting_desc:before, div.dataTables_scrollBody table thead .sorting:after, div.dataTables_scrollBody table thead .sorting_asc:after, div.dataTables_scrollBody table thead .sorting_desc:after {
	display: none;
}
div.dataTables_scrollBody table tbody tr:first-child th, div.dataTables_scrollBody table tbody tr:first-child td {
	border-top: none;
}
div.dataTables_scrollFoot>.dataTables_scrollFootInner {
	box-sizing: content-box;
}
div.dataTables_scrollFoot>.dataTables_scrollFootInner>table {
	margin-top: 0 !important;
	border-top: none;
}
@media screen and (max-width: 767px) {
	div.dataTables_wrapper div.dataTables_length, div.dataTables_wrapper div.dataTables_filter, div.dataTables_wrapper div.dataTables_info, div.dataTables_wrapper div.dataTables_paginate {
		text-align: center;
	}
}
table.dataTable.table-sm>thead>tr>th {
	padding-right: 20px;
}
table.dataTable.table-sm .sorting:before, table.dataTable.table-sm .sorting_asc:before, table.dataTable.table-sm .sorting_desc:before {
	top: 5px;
	right: 0.85em;
}
table.dataTable.table-sm .sorting:after, table.dataTable.table-sm .sorting_asc:after, table.dataTable.table-sm .sorting_desc:after {
	top: 5px;
}
table.table-bordered.dataTable th, table.table-bordered.dataTable td {
	border-left-width: 0;
}
table.table-bordered.dataTable th:last-child, table.table-bordered.dataTable th:last-child, table.table-bordered.dataTable td:last-child, table.table-bordered.dataTable td:last-child {
	border-right-width: 0;
}
table.table-bordered.dataTable tbody th, table.table-bordered.dataTable tbody td {
	border-bottom-width: 0;
}
div.dataTables_scrollHead table.table-bordered {
	border-bottom-width: 0;
}
div.table-responsive>div.dataTables_wrapper>div.row {
	margin: 0;
}
div.table-responsive>div.dataTables_wrapper>div.row>div[class^="col-"]:first-child {
	padding-left: 0;
}
div.table-responsive>div.dataTables_wrapper>div.row>div[class^="col-"]:last-child {
	padding-right: 0;
}

/*
 * File Upload
 * ----------------------
 */

.files input {
	outline: 2px dashed #f0f0f2;
	outline-offset: -10px;
	-webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
	transition: outline-offset .15s ease-in-out, background-color .15s linear;
	padding: 120px 0px 85px 35%;
	text-align: center !important;
	margin: 0;
	width: 100% !important;
}
.files input:focus {
	outline: 2px dashed #f0f0f2;
	outline-offset: -10px;
	-webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
	transition: outline-offset .15s ease-in-out, background-color .15s linear;
	border: 1px solid #f0f0f2;
}
.files {
	position: relative
}
/* .files:after {
	pointer-events: none;
	position: absolute;
	top: 80px;
	left: 0;
	width: 50px;
	right: 0;
	height: 56px;
	content: '\f019';
	font-family: fontawesome;
	margin: 0 auto;
	font-size: 2em;
} */
.color input {
	background-color: #f4f7fe;
}

.dropdown a{
	font-size: 14px !important;
}

.dropdown-menu {
	border: none;
	-webkit-box-shadow: 0 0 25px rgba(0, 0, 0, .15);
	box-shadow: 0 0 25px rgba(0, 0, 0, .15);
	/* width: 200px; */
}
.dropdown-menu a {
	font-size: 14px !important;
}
.dropdown-menu a:hover{
	background-color:#3454f5;
	color:#fff;
}
.dropdown-item {
	padding: 10px 20px;
	line-height: 1.5;
	color: #3454f5;
}
.dropdown-divider {
	background-color: #f0f0f2;
	border-top: 1px solid #f0f0f2;
}
.dropdown-list {
	width: 350px;
	padding: 0;
}
.dropdown-list .dropdown-item:first-child {
	border-top: 1px solid #f0f0f2;
}
.dropdown-list .dropdown-item {
	display: inline-block;
	width: 100%;
	padding-top: 15px;
	padding-bottom: 15px;
	font-size: 12px;
	border-bottom: 1px solid #f0f0f2;
}
.dropdown-list .dropdown-item.dropdown-item-unread:active .dropdown-item-desc, .dropdown-list .dropdown-item.dropdown-item-unread:active .dropdown-item-desc b {
	color: #ab7fa5;
}
.dropdown-list .dropdown-item:active .dropdown-item-desc, .dropdown-list .dropdown-item:active .dropdown-item-desc b {
	color: #ab7fa5;
}
.dropdown-list .dropdown-item.dropdown-item-unread {
	background-color: #f4f7fe;
	border-bottom-color: #f0f0f2;
}
.dropdown-list .dropdown-header {
	letter-spacing: .5px;
	font-weight: 500;
	padding: 15px;
	color: #3454f5;
	font-size: 14px;
}
.dropdown-item .dropdown-item .has-icon:hover {
	background-color: #f4f7fe;
}
.dropdown-list .dropdown-item.dropdown-item-header:hover {
	background-color: #f4f7fe;
}
.dropdown-list .dropdown-item:hover {
	background-color: #f4f7fe;
}
.dropdown-item :focus {
	background-color: #f4f7fe;
}
.dropdown-list .dropdown-item .time {
	margin-left: 30px;
	font-weight: 500;
	font-size: 10px;
}
.dropdownmsg {
	margin-top: 5px;
}
.dropdown-list .dropdown-item .dropdown-item-img {
	float: left;
	width: 40px;
}
.dropdown-list .dropdown-item i {
	float: left;
	font-size: 20px;
}
.dropdown-list .dropdown-item .dropdown-item-desc {
	margin-left: 60px;
	white-space: normal;
	color: #24273c;
}
.dropdown-list .dropdown-item .dropdown-item-desc b:hover{
	color:#3454f5;
}
.dropdown-list .dropdown-item .dropdown-item-desc p {
	margin-top: 5px;
	margin-bottom:0;
}
.dropdown-list .dropdown-list-content {
	overflow: hidden;
}
.navbar .dropdown-menu-right{
	border-color: #ccc;
	color:#777;
}

.dropdown-menu-right span{
	font-size: 14px !important;
}


.navbar-dropdown__icon{
	width: 18px !important;
}

.hamburger__icon{
	width: 20px !important;
}

.navbar .dropdown-menu-right:before {
    content:"";
    position: absolute;
    right: 11px;
    top: -10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #fff transparent;
    z-index:9999;
}
.navbar-nav .dropdown-item{
	color:#24273c;
}
.navbar-nav .dropdown-menu a:hover{
	background-color:#f4f7fe !important;
	/* color:#3454f5; */
	color: #01cf6b
}
.navbar-nav .dropdown-menu i{
	/* color:#3454f5; */
	color: #01cf6b
}
.navbar .dropdown-menu-right:before{
	left: auto;
	right: 12px;
}
.dropdown-toggle {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: pointer;
}

.btn {
	font-size: 14px;
	line-height: 23px;
	padding: 5px 23px;
	letter-spacing: .4px;
	box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
}
.btn:active, .btn:focus, .btn:hover {
	border-color: transparent !important;
}
.btn.btn-shadow {
	-webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, .13);
	box-shadow: 0 10px 20px rgba(0, 0, 0, .13);
}
.btn>i {
	margin-left: 0 !important;
}
.btn.btn-lg {
	padding: .6rem 1.1rem;
	font-size: 18px;
}
.btn.btn-sm {
	padding: .1rem .7rem;
	font-size: .7rem;
}
.btn.btn-icon .ion, .btn.btn-icon .fas, .btn.btn-icon .far, .btn.btn-icon .fab, .btn.btn-icon .fal {
	margin-left: 0 !important;
	font-size: 14px;
}

/*-- end btn  --*/

/*
 * Nav Bar
 * ----------------------
 */

.navbar {
	height: 60px;
	left: 0;
	top: 0;
	right: 0;
	position: fixed !important;
	z-index: 890;
	background: #fff;
	/* background: #0055a6; */
	color: #fff;
	padding-top: 0;
	padding-bottom: 0;
	/* margin-left: 212px; */
	/* box-shadow:0 0px 4px 0px rgba(0,0,0,0.16), 0 -2px 6px 0 rgba(0,0,0,0.12) !important; */
	box-shadow: 0px -1px 6px 0px rgba(0,0,0,0.1) !important;
}
.navbar.active {
	background-color: #3454f5;
}
.navbar .form-inline .form-control {
	background: #f4f7fe;
    border: 1px solid #dee2e6;
    border-radius: 3px;
    color: #24273c;
    padding-left: 20px;
	padding-right: 0;
	margin-right: -6px;
	width: 200px;
	border-bottom-right-radius: 0;
    border-top-right-radius: 0;
	border-right: 0;
}

.header-search{
	border-radius: 25px !important;
	font-size: 13px !important;
}
.header-form .btn{
	color: #0055a6;
}

.header-form span{
	right:0;
	top: -1px;
}

.navbar .form-inline .btn  {
	border-radius: 0;
    padding: 5px 15px 6px 15px;
    border-color: transparent;
	border-bottom-right-radius: 7px;
    border-top-right-radius: 7px;
}
.search-element{
	padding-right: 15px !important;
}
.navbar-right{
	height: 100%;
	align-items:center
}
.navbar-nav .input-icon-addon {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 8px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    min-width: 2.5rem;
    pointer-events: none;
    color: #fff;
}
.navbar .nav-link {
	color: #2f3245;
	padding-left: 15px !important;
	padding-right: 15px !important;
}
.navbar .nav-link.nav-link-lg div {
	margin-top: 3px; 
	margin-left: 5px;
	color: #0055a6
}
.dashboard-header .hamburger{
	height: 100%;
	padding-left: 0 !important;
}
.navbar .nav-link.nav-link-lg i {
	font-size: 20px;
	margin-top: 6px;
	color: #0055a6;
	/* color: #fff; */
}

.navbar .search-element .btn-primary{
	background: #0055a6 !important;
	border-color: #0055a6 !important;

}
.dropdown .dropdown-list-toggle{
	display:inline;
	color: #777 !important;
}
.navbar .nav-link:hover {
	color: #3454f5;
}
.navbar .nav-link.disabled {
	color: #fff;
	opacity: .6;
}
.nav-tabs .nav-item .nav-link {
	color: #3454f5;
}
.nav-tabs .nav-item .nav-link.active {
	color: #3454f5;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
	color: #495057;
	background-color: #fff;
	border-color: #f0f0f2 #f0f0f2 #fff;
}
.tab-content>.tab-pane {
	padding: 10px 0;
	line-height: 24px;
}
.tab-bordered .tab-pane {
	padding: 15px;
	border: 1px solid #f0f0f2;
	margin-top: -1px;
}
.nav-pills .nav-item .nav-link {
	/* color: #28cf6b; */
	color: #0055a6;
	border-radius: 2px;
	padding-left: 15px !important;
	padding-right: 15px !important;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link, .nav-pills .nav-item .nav-link.active {
	color: #fff;
	/* background: #28cf6b; */
	background: #0055a6;
}
.nav-pills .nav-item .nav-link .badge {
	padding: 5px 8px;
	margin-left: 5px;
}
/*- end navbar --*/

/*
 * Form Control
 * ----------------------
 */
  
 .form-control{
	 min-height: 30px !important;
	 height: 35px !important;
 }
 textarea.form-control{
	 height: auto !important;
 }
.input-lg {
	height: 46px;
	padding: 10px 16px;
	font-size: 18px;
	line-height: 1.3333333;
	border-radius: 6px;
}
.input-sm {
	height: 30px;
	padding: 5px 10px;
	font-size: 12px;
	line-height: 1.5;
	border-radius: 3px;
}
.input-group-addon {
	padding: 0 12px;
    line-height: 36px;
	font-size: 14px;
	font-weight: 400;
	color: #3454f5;
	text-align: center;
	background-color: #eee;
	border: 1px solid #f0f0f2;
	border-bottom-right-radius: 0.23rem !important;
	border-top-right-radius: 0.23rem !important;
}
.input-group .input-group-addon {
	border-radius: 0;
	border-color: #f0f0f2;
	background-color: #fff;
}
.form-control:disabled, .form-control[readonly] {
	background-color: #f7f7fa;
}
.input-group-addon:first-child {
	border-right: 0;
}
.form-control {
	border: 1px solid #f0f0f2;
}
.form-control:focus {
	border-color: #3454f5;
}
.input-group-text, select.form-control:not([size]):not([multiple]), .form-control:not(.form-control-sm):not(.form-control-lg) {
	font-size: 12px;
	padding: 6px 15px;
	line-height: 13px;
}
.navbar .form-inline .btn {
    border-radius: 0;
    padding: 5px 15px 6px 15px;
    border: 2px solid #3454f5;
    box-shadow: none;
	border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
}
.card {
	background-color: #fff;
	position: relative;
	margin-bottom: 30px;
}


.card .card-header, .card .card-footer, .card .card-body {
	background-color: transparent;
}
.card .card-body {
	padding:30px;
}
.card .card-footer.card-footer-grey {
	background-color: #fbfbfb;
}
.card .card-header {
	border-bottom-color: transparent;
	line-height: 30px;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
	width: 100%;
}
.card-header {
    padding: 15px 30px;
}
.card .card-header:before{
	content:'';
	width:3px;
	height:30px;
	background: #0055a6;
	position:absolute;
	display:block;
	left:13px;
		
}
.card .card-header .btn {
	padding: 0;
    font-size: 12px;
    border: 1px solid #edeaea;
    width: 30px;
    text-align: center;
	height: 30px;
	color: #0055a6 !important;
	border-color: #0055a6;
}
.card .card-header .add-appointment{

	border-radius: 0;
	line-height: 30px;
	padding: 0px 10px;
	background-color: #0055a6 !important;
    width: initial;
    text-align: center;
	height: initial;
	z-index: 0;
	color: #fff !important;
}

.card .card-header .form-control {
	font-size: 12px;
}
.card .card-header .form-control+.input-group-btn .btn {
	border-radius: 0 3px 3px 0;
	margin-top: -3px;
}
.card .card-header .float-right .btn-group .btn {
	border-radius: 30px !important;
	padding-left: 13px !important;
	padding-right: 13px !important;
}
.card .card-header h4 {
	font-size: 20px;
	font-weight: 400;
	letter-spacing: 0;
	margin:0;
	line-height: 30px;
}
.card .card-header h4 a.unstyled {
	font-size: 14px;
}
.card .card-footer {
	border: none;
	padding-top: 10px;
	padding-bottom: 10px;
	border-top: 1px solid #f0f0f2;
	background: #fbfcfd;
}

.icon1 {
	font-size: 40px;
}

.card .box {
	position: relative;
	top: 50%;
	left: 0;
	text-align: center;
	padding: 20px;
	box-sizing: border-box;
	border: 1px solid #f0f0f2;
	border-radius: 4px;
	box-shadow: 0 0 25px rgba(255, 255, 255, 0.3);
	width: 100%;
}
.card .box .img {
	width: 120px;
	height: 120px;
	margin: 0 auto;
	border-radius: 50%;
	overflow: hidden;
}
.card .box .img img {
	width: 100%;
	height: 100%;
}
.card .box h2 {
	font-size: 20px;
	color: #3454f5;
	margin: 20px auto;
}
.card .box h2 span {
	font-size: 14px;
	/* background: #01cf6b; */
	background: #0055a6;
	color: #fff;
	display: inline-block;
	padding: 4px 10px;
	border-radius: 15px;
	margin-top: 10px;
}
.card .box ul {
	margin: 0;
	padding: 0;
}
.card .box ul li {
	list-style: none;
	float: left;
}
.card .box ul li a {
	display: block;
	margin: 0 10px;
	font-size: 20px;
	transition: 0.5s;
	text-align: center;
}
.card .box ul.icons li {
	width: 20%;
    font-size: 20px;
    line-height: 3;
    list-style: none;
    text-align: center;
    border-radius: 0;
    position: relative;
    border: 1px solid #f0f0f2;
    border-left:0;
    cursor: pointer;
}
.card .box ul li:hover a {
	color: #6b0d5d;
	transform: rotateY(360deg);
}
/*-- end card --*/

@media(max-width:320px){
	.bg-light h4{
		font-size: .85rem !important;
	}
}
@media (max-width:375px){
	
	.timelineleft>li>.timelineleft-item>.timelineleft-header {
		font-size: 12px !important;
		color:#232323 !important;
	}
}
@media (max-width:414px){

	.timelineleft-body img{
		margin-bottom:3px;
	}
	.form-group label{
		font-size:11px;
	}

}
.table-striped tbody tr:nth-of-type(odd) {
	background-color: #f4f7fe;
}
/*-- End Table --*/
/*
 * Modal
 * ----------------------
 */

.modal-header {
	border-bottom: none;
	border-bottom:1px solid #f0f0f2;
}
.modal-header h5 {
	font-size: 18px;
}
.modal-footer {
	border-top:1px solid  #f0f0f2;
}
.modal-content {
	border: none;
	-webkit-box-shadow: 0 0 40px rgba(0, 0, 0, .05);
	box-shadow: 0 0 40px rgba(0, 0, 0, .05);
}
/*-- end modal --*/

/*
 * Profile widget
 * ----------------------
 */

.card-profile-widget .profile-widget-picture {
	float: left;
	width: 100px;
	-webkit-box-shadow: 0 5px 40px rgba(0, 0, 0, .2);
	box-shadow: 0 5px 40px rgba(0, 0, 0, .2);
	margin: -35px -5px 0 30px;
	position: relative;
	z-index: 1;
}
.card-profile-widget .profile-widget-header {
	display: inline-block;
	width: 100%;
	margin-bottom: 10px;
}
.card-profile-widget .profile-widget-items {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: relative;
}
.card-profile-widget .profile-widget-items:after {
	content: ' ';
	position: absolute;
	bottom: 0;
	left: -25px;
	right: 0;
	height: 1px;
	background-color: #f0f0f2;
}
.card-profile-widget .profile-widget-items .profile-widget-item {
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
	text-align: center;
	border-right: 1px solid #f0f0f2;
	padding: 10px 0;
}
.card-profile-widget .profile-widget-items .profile-widget-item:last-child {
	border-right: none;
}
.card-profile-widget .profile-widget-items .profile-widget-item .profile-widget-item-label {
	font-weight: 500;
	text-transform: uppercase;
	font-size: 10px;
	letter-spacing: .5px;
	color: #ab7fa5;
}
.card-profile-widget .profile-widget-items .profile-widget-item .profile-widget-item-value {
	color: #232323;
	font-weight: 600;
	font-size: 16px;
}
.card-profile-widget .profile-widget-description {
	padding: 20px;
	font-size: 12px;
	line-height: 22px;
}
.card-profile-widget .profile-widget-description .profile-widget-name {
	font-size: 14px;
	margin-bottom: 10px;
	font-weight: 600;
}
/*-- end profile widget --*/

/*
 * User Profile
 * ----------------------
 */
.profile.card::hover{
    background-color: #a50b8e !important;
}
.nav .nav-pills>li>a {
	border-radius: 5px;
	text-align: left;
}
.nav>li>a {
	position: relative;
	display: block;
	margin-right: 11px;
}
.media-body {
	color: #24273c;
	overflow: visible;
	margin-left: 15px;
}
.media-body h4 {
	font-size: 16px;
	color: #6c739f;
}
.media-body p {
	font-size: 13px !important;
}
.media-body .nav-pills li a {
	color: #a8b5bf;
}
.media-body .nav-pills li a:hover {
	text-decoration: none;
	color: #3454f5;
}

.media:first-child {
	margin-top: 15px;
} 

.btn-circle {
	width: 30px;
	height: 30px;
	padding: 6px 0;
	border-radius: 15px;
	text-align: center;
	font-size: 12px;
	line-height: 1.428571429;
}
/*-- listgroup --*/

.list-group-item.active {
	z-index: 2;
	color: #fff;
	background-color: #3454f5;
	border-color: #3454f5;
}
/*-- Badge --*/

.badge {
	padding: 7px 12px;
	font-weight: 500;
	letter-spacing: .5px;
	border-radius: 30px;
	box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
}
.badge-primary {
	color: #fff;
	background-color: #3454f5;
}
.badge-secondary {
	color: #fff;
	background-color: #3e1088;
}
.badge-warning {
	color: #fff;
	background-color: #fe7100;
}
.badge-success {
	color: #fff;
	background-color: #01cf6b;
}
.badge-danger {
	color: #fff;
	background-color: #fa292e;
}
.badge-info {
	color: #fff;
	background-color: #01b8ff;
}
.badge-cyan {
	color: #fff;
	background-color: #1891f2;
}
/*-- end badge --*/

/*
 * Media
 * ----------------------
 */
.media .media-title {
	margin-top: 0;
	margin-bottom: 5px;
	font-weight: 500;
}
.media .media-title a {
	color: #232323;
}
.media .media-description {
	line-height: 24px;
	color: #ab7fa5;
}
.media .media-links {
	margin-top: 10px;
}
.media .media-links a {
	font-size: 12px;
	color: #999;
}
.media .media-cta {
	margin-left: 40px;
}
.media .media-cta .btn {
	padding: 5px 15px;
	border-radius: 30px;
	font-size: 12px;
}
.media .media-items {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.media .media-items .media-item {
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
	text-align: center;
	padding: 0 15px;
}
.media .media-items .media-item .media-label {
	font-weight: 500;
	font-size: 12px;
	color: #ab7fa5;
}
.media .media-items .media-item .media-value {
	font-weight: 700;
	font-size: 18px;
}
/*-- end media --*/

/*
 * Toast
 * ----------------------
 */

#toast-container>div {
	-webkit-box-shadow: 0 0 25px rgba(0, 0, 0, .15) !important;
	box-shadow: 0 0 25px rgba(0, 0, 0, .15) !important;
	padding: 20px 20px 20px 50px;
	opacity: 1;
}
#toast-container>.toast {
	background-image: none !important;
}
#toast-container>.toast:before {
	position: absolute;
	left: 17px;
	top: 25px;
	font-family: 'Ionicons';
	font-size: 24px;
	line-height: 18px;
	color: #fff;
}
#toast-container>.toast-warning:before {
	content: "\f100";
}
#toast-container>.toast-error:before {
	content: "\f2d7";
}
#toast-container>.toast-info:before {
	content: "\f44c";
	color: #fff;
}
#toast-container>.toast-success:before {
	content: "\f121";
}
.toast.toast-error {
	background-color: #ac243a;
}
.toast.toast-warning {
	background-color: #f6d30c;
}
.toast.toast-success {
	background-color: #00d02f;
}
.toast.toast-info {
	background-color: #fff;
}
.toast.toast-info .toast-title, .toast.toast-info .toast-message {
	color: #fff;
}
.toast.toast-info .toast-message {
	margin-top: 5px;
}
/*-- end toast --*/

/*-- custom tab --*/

[data-tab-group] {
	display: none;
}
[data-tab-group].active {
	display: block;
}
/*-- end custom tab --*/

/*--- Breadcrumb --*/

.breadcrumb {
	color: #24273c;
	background: transparent;
	margin-bottom: 10px;
	border-radius:7px;
	padding: .75rem 0 ;
}
.breadcrumb-item a{
	color:#9ca1b9 ;
} 
/*-- end breadcrumb --*/

/*
 * Accordion
 * ----------------------
 */

.accordion {
	display: inline-block;
	width: 100%;
	margin-bottom: 10px;
}
.accordion .accordion-header, .accordion .accordion-body {
	padding: 10px 15px;
}
.accordion .accordion-header {
	background-color: #f4f7fe;
	border-radius: 3px;
	cursor: pointer;
	-webkit-transition: all .5s;
	-o-transition: all .5s;
	transition: all .5s;
}
.accordion .accordion-header h4 {
	margin: 0;
	font-size: 14px;
}
.accordion .accordion-header:hover {
	background-color: #f4f7fe;
}
.accordion .accordion-header[aria-expanded="true"] {
	-webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, .07);
	box-shadow: 0 10px 30px rgba(0, 0, 0, .07);
	background-color: #3454f5;
	color: #fff;
}
.accordion .accordion-body {
	line-height: 24px;
}
/*-- end accordion --*/

/*
 * Popover
 * ----------------------
 */

.popover {
	border-color: transparent;
	-webkit-box-shadow: 0 0 40px rgba(0, 0, 0, .05);
	box-shadow: 0 0 40px rgba(0, 0, 0, .05);
}
.popover .manual-arrow {
	position: absolute;
	bottom: -15px;
	font-size: 16px;
	left: 50%;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	color: #fff;
}
.bs-popover-auto[x-placement^=left] .arrow::before, .bs-popover-left .arrow::before {
	border-left-color: #f0f0f2;
}
.bs-popover-auto[x-placement^=bottom] .arrow::before, .bs-popover-bottom .arrow::before {
	border-bottom-color: #f0f0f2;
}
.bs-popover-auto[x-placement^=top] .arrow::before, .bs-popover-top .arrow::before {
	border-top-color: #f0f0f2;
}
.bs-popover-auto[x-placement^=right] .arrow::before, .bs-popover-right .arrow::before {
	border-right-color: #f0f0f2;
}
.popover .popover-header {
	background-color: transparent;
	border: none;
	padding-bottom: 0;
	padding-top: 10px;
}
.popover .popover-body {
	padding: 15px;
	line-height: 24px;
}
/*-- end popover --*/

.icons-item {
	padding: 0;
	margin: 0;
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}
.icons-item li {
	width: 60px;
    height: 60px;
    font-size: 20px;
    line-height: 3;
	margin:1px;
    list-style: none;
    text-align: center;
    border-radius: 3px;
    border-radius: 4px;
    position: relative;
    border: 1px solid #f0f0f2;
    cursor: pointer;
}
.icons {
	padding: 0;
	margin: 0;
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}
.icons li {
	width: 60px;
    height: 60px;
    font-size: 20px;
    line-height: 3;
    list-style: none;
    text-align: center;
    border-radius: 3px;
    border-radius: 4px;
    position: relative;
    border: 1px solid #f0f0f2;
    cursor: pointer;
}
.icons li:hover {
	opacity: .8;
}
.icons li .icon-name {
	display: none;
}
/*-- end ionicons--- */

/*-- Transparent--- */

.transparent:before{
	content:'';
	position:absolute;
	background:rgba(69, 16, 121,0.7);
	width:100%;
	height:100%;
	left:0;
	right:0;
	display:block;
	bottom: 0;
	top: 0;
	border-radius:7px 0 0 7px;
}
.cover-image{
	background-size: cover !important;
}
/*
 * Form Elements
 * ----------------------
 */
.form-group label, .col-form-label {
	font-weight: 400;
	font-size: 11px;
	letter-spacing: .5px;
}
.custom-control-label::before {
	position: absolute;
	left: -1.5rem;
	display: block;
	width: 1rem;
	height: 1rem;
	pointer-events: none;
	content: "";
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background-color: #dee2e6;
}
.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: #3454f5;
}
.input-group-text {
	background-color: transparent;
}
/*-- end form group --*/

@media (max-width:992px){
	.transparent:before{
		border-radius:7px 7px 0 0;
	}
}
.terms ul{
	padding:0;
	list-style:none;
}

/*----Signle-pages----*/

.single-page{
	width: 70%;
	/* margin: 120px auto; */
	margin: 48px auto;
	background: rgba(255,255,255,.5);
    padding: 30px;
	display:table;
	position: relative;
	border-radius:7px;
	box-shadow: 0 0 4px rgba(0,0,0,.14), 0 4px 8px rgba(0,0,0,.28);
}
.single-page.single-pageimage{
	min-height: 387px;
}
@media(min-width:1024px){
	.navbar .form-inline .form-control{
		width:180px
	}
	.mt-xl-5, .my-xl-5 {
		margin-top: 6rem!important;
	}
	.mt-xl-6, .my-xl-6 {
		margin-top: 3rem!important;
	}
}

/*.single-page form a.btn{
	display: inline-block;
	padding: 10px 30px;
	border-radius: 50px;
	position: relative;
	color: #FFF;
	transition: all .25s;
}*/
.single-page button.dark{
	border-color: #ff4931;
	background: #ff4931;
}

.single-page button:active{
	box-shadow: none;			
}
.single-page button:focus{
	outline: none;			
}

.single-page .wrapper.wrapper2 {
	top:-37%;
	left:4%;
}
.single-page .wrapper.wrapper2 {
	background: #fff;
	width: 100%;
	background: #fff;
	box-shadow: 0 0 4px rgba(0,0,0,.14), 0 4px 8px rgba(0,0,0,.28);
	transition: all .5s;
	color: #24273c;
	overflow: hidden;
	border-radius:7px;
	text-align: center;
}
.single-page .wrapper{
	background: #fff;
	box-shadow: 0 0 4px rgba(0,0,0,.14), 0 4px 8px rgba(0,0,0,.28);
	transition: all .5s;
	color: #24273c;
	overflow: hidden;
	border-radius:7px;
}
.single-page .wrapper > form{
	width: 100%;
	transition: all .5s;
	background: #fff;
	width: 100%; 
}
.single-page .wrapper .card-body{
	padding: 30px;
}
.single-page .wrapper > form:focus{
	outline: none;
}
.single-page .wrapper #login{
	visibility: visible;
}
.single-page .wrapper #register{
	transform: translateY(-80%) translateX(100%);
	visibility: hidden;
}
.single-page .wrapper.move #register{
	transform: translateY(-80%) translateX(0%);
	visibility: visible;
}
.single-page .wrapper.move #login{
	transform: translateX(-100%);
	visibility: hidden;
}
.single-page .wrapper > form > div {
	position: relative;
	margin-bottom: 15px;
}

.single-page .wrapper h3, .single-page .wrapper h4{
	margin-bottom: 25px;
	font-size: 22px;
}
.single-page .wrapper p{
	margin-bottom: 22px;
}
.single-page .wrapper input{
	height: 40px;
	padding: 5px 15px;
	width: 100%;
	border: solid 1px #f0f0f2;
}
.single-page .wrapper input{
	height: 40px;
	padding: 5px 15px;
	width: 100%;
	border: solid 1px #f0f0f2;
}
.single-page .wrapper input:focus{
	outline: none;
	border-color: #f0f0f2;
}

@media (min-width:768px) and (max-width: 992px) {
	#calendar .fc-toolbar h2{
		font-size:11px;
		margin-top: 7px;
	}
	.fc button{
		 font-size: 0.65rem !important;
	}
}
@media (max-width: 480px){
	.nav-tabs .nav-item {
		width: 100%;
	}
	.nav-tabs .nav-item .nav-link.active{
		border:1px solid #f0f0f2;
	}
	.nav .nav-item {
		width: 100%;
	}
	.breadcrumb .ml-auto .sparkline_bar{
		float:right;
	}
	
}
@media (max-width: 568px){
	.fc-toolbar .fc-left, .fc-toolbar .fc-right, .fc-icon, .fc-toolbar .fc-center {
		float: none;
		display: block !important;
		width: 100%;
		margin-bottom: 0.5rem;
		text-align: center;
	}
	.single-page{
		border-radius: 0;
	}

	.dashboard-header.show-drawer .navbar-right{
		display: none;
	}

	.filter-dropdown,.dashboard-filter__btn{
		width:100% !important
	}
}
@media (max-width: 992px){
	.single-page{
		margin: 0;
		width: 100%;
		display: block;
	}
	.single-page .wrapper.wrapper2{
		position: relative;
		height: auto;
		top: 0;
		left: 0;
		width: 100%;
	}
	.single-page .wrapper.wrapper2 {
		top: 0;
		left: 0;
	}

}

input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill{
  box-shadow: inset 0 0 0 50px #fff
}

/*---Count-down---*/

#count-down {
	margin: 20px auto;
    text-align: center;
    display: inline-block;
}
#count-down .clock-presenter {
	height: 80px;
	padding: 0px;
	text-align: center;
}
#count-down .clock-presenter .digit {
	margin-top: 10px;
	font-size: 1.5rem;
	line-height: 40px;
	height: 40px;
	display: inline-block;
	overflow: hidden;
	text-align: center;
	position: relative;
	cursor: default;
	border-radius:3px;
}
#count-down .clock-presenter {
	width:60px;
	height:60px;
	border-radius:50%;
	background:rgba(0,0,0,0.4);
	float: left;
    margin: 0 20px;
}
#count-down .clock-presenter .note {
	position: relative;
	margin-bottom: 0px;
	cursor: default;
	font-size: 16px;
	opacity: 0.7;
}
@media (max-width: 992px) {
	#count-down .clock-presenter .digit {
		font-size: 42px;
	}
	.construction {
		padding: 70px 0 !important;
	}
}
@media (max-width: 568px) {
	#count-down .clock-presenter {
		float: none !important;
		margin-bottom: 45px;
	}
}
@media (max-width: 767px) {
	#count-down .clock-presenter {
		float: left;
		margin-bottom: 45px;
	}
	#count-down .clock-presenter .digit {
		font-size: 20px;
	}
	#count-down .hours_dash {
		border-right: none;
	}
	.construction h1 {
		font-size: 42px !important;
	}
	
}

/*-- General Css---*/

.ct-chart-pie{
	height:292px !important;
}
.chartist-tooltip { 
	position: relative;
}
.section-2{
	height:10rem;
	position:relative;
}
.section-2 .section-3{
	margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
}
.mt-6, .my-6 {
    margin-top: 4rem!important;
}
.sparkline.sparkline2 canvas{
	width:auto !important;
}
.sparkline canvas{
	width:100% !important;
}
.cover-image {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
	background-size: cover !important;
}
.cover-image2 {
    width: 100%;
    height: 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover !important;
    position: relative;
}

.construction-bg:before{
	content:'';	
	position:absolute;
	background:linear-gradient(rgba(52, 84, 245, 0.8),rgba(1, 110, 202, 0.8));
	width:100%;
	height:100%;
	left:0;
	top:0;
	bottom:0;
	right:0;
	border-radius:7px;
}
.bg-transaprent .dash2{
	position:relative;
}
	
.bg-transaprent:before{
	content:'';	
	position:absolute;
	background:rgba(0,0,0,0.75);
	width:100%;
	height:100%;
	left:0;
	top:0;
	bottom:0;
	border-radius:7px;
	right:0;
}
#count-down{
	position:relative;
}
.construction p{
	font-size:20px;
}
.construction{
	position:relative;
}
.knob-chart .dash3{
	margin-top: 13px;
}
.knob-chart .dash3{
	margin-top: 13px;
}
/*
 * Responsive
 * ----------------------
 */

@media (max-width: 575.98px) {
	.section .section-header .float-right {
		display: inline-block;
		width: 100%;
		margin-top: 15px;
	}
	.section .section-header {
		margin-bottom: 20px !important;
	}
	.statistic-details {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}
	.statistic-details .statistic-details-item {
		-webkit-box-flex: initial;
		-ms-flex: initial;
		flex: initial;
		width: 50%;
	}
	.article.article-style-c .article-header {
		height: 225px;
	}
	body.search-show .navbar .form-inline .search-element {
		display: block;
	}
	.navbar .form-inline .search-element {
		position: absolute;
		top: 10px;
		left: 10px;
		right: -6px;
		z-index: 892;
		display: none;
	}
	.navbar .nav-link.nav-link-lg div {
		display: none;
	}
	.navbar .form-inline .search-element .form-control {
		float: left;
		border-radius: 3px 0 0 3px;
		width: calc(100% - 41px);
	}
	.navbar .form-inline .search-element .btn {
		border-radius: 0 3px 3px 0;
	}
	.search-element{
		padding-top:2px;
	}
	/* .navbar .form-inline .form-control::-webkit-input-placeholder{
		color:#232323;
	} */
	.navbar .form-inline .form-control {
		background: rgb(225,225,225);
		border: 1px solid rgb(225,225,225);
		color: #232323;
	}
	.dropdown-list-toggle {
		position: static;
		display: none;
	}
	.main-navbar .header-brand {
		min-width: 130px;
	}
	.dropdown-list-toggle .dropdown-list {
		left: 10px !important;
		width: calc(100% - 20px);
	}
	.card-author-box .author-box-left {
		float: none;
	}
	.card-author-box .author-box-details {
		margin-left: 0;
		margin-top: 15px;
		text-align: center;
	}
	.card-profile-widget .profile-widget-picture {
		left: 50%;
		-webkit-transform: translate(-50%, 0);
		-ms-transform: translate(-50%, 0);
		transform: translate(-50%, 0);
		margin: 40px 0;
		float: none;
	}
	.card-profile-widget .profile-widget-items .profile-widget-item {
		border-top: 1px solid #f0f0f2;
	}
	.user-progress .media, .user-details .media {
		text-align: center;
		display: inline-block;
		width: 100%;
	}
	.user-progress .media img, .user-details .media img {
		margin: 0 !important;
		margin-bottom: 10px !important;
	}
	.user-progress .media .media-body, .user-details .media .media-body {
		width: 100%;
	}
	.user-progress .media .media-items, .user-details .media .media-items {
		margin: 20px 0;
		width: 100%;
	}
	.user-progress .list-unstyled-noborder li:last-child, .user-details .list-unstyled-noborder li:last-child {
		margin-bottom: 0;
		padding-bottom: 0;
	}
	.user-progress .media .media-cta {
		margin-top: 20px;
		margin-left: 0;
	}
	.fc-overflow {
		width: 100%;
		overflow: auto;
	}
	.fc-overflow #myEvent {
		width: 800px;
	}
	.ionicons li {
		width: calc(100% / 4);
	}
	.dropzone .dz-message {
		margin: 2em;
	}
}
@media(min-width:1024px){

}
@media (min-width: 576px) and (max-width: 767.98px) {
	.navbar .form-inline .search-element {
		display: none;
	}
}
@media (max-width: 320px) {
	.navbar .form-inline .navsearch {
		display: none;
	}
	.timelineleft-footer btn{
		margin-bottom:2px !important;
	}
	.bg-light label{
		font-size:9px;
	}
	.profile .social-icons{
		    margin: 0 -6px !important;
	}
	.profile .social-icons .icon {
		margin-left:2.5px;
		margin-right:2.5px;
	}
	.list .pb-3, .py-3 {
    padding-bottom: .50rem!important;
	}	
	
}
@media (min-width: 768px) and (max-width: 991.98px) {
	.article {
		margin-bottom: 40px;
	}
	.article.article-style-c .article-header {
		height: 155px;
	}
}
@media (max-width:767px){
	.main-footer {
		padding-left: 20px;
		margin-left:0;
	}
	.sidenav-toggled .main-footer {
		padding-left: 20px;
		margin-left:0;
	}
}

@media (min-width: 768px) and (max-width: 991.98px) {
	.article {
		margin-bottom: 40px;
	}
	.article.article-style-c .article-header {
		height: 155px;
	}
}
@media (max-width: 414px) {
	.navbar .nav-link.nav-link-lg i {
		font-size: 20px;
		margin-top: 5px;
		padding-left:10px;
	}
}
@media (max-width: 1024px) {
	.main-sidebar {
		position: fixed;
		z-index: 891;
	}
	.main-content {
		padding-left: 30px;
	}
	.navbar {
		left: 0x;
		right: 0;
	}
	.navbar .dropdown-menu {
		position: absolute;
	}
	.navbar .navbar-nav {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
	}
	.navbar-expand-lg .navbar-nav .dropdown-menu.dropdown-menu-right {
		right: 0;
		left: auto;
		border:none !important;
		box-shadow:0 15px 20px 0 rgba(0,0,0,0.1) !important
	}
	
	body.search-show .navbar {
		z-index: 892;
	}
	body.search-show, body.sidebar-show {
		overflow: hidden;
	}
	body.search-show:before, body.sidebar-show:before {
		content: '';
		position: fixed;
		left: 0;
		right: 0;
		width: 100%;
		height: 100%;
		background-color: #232323;
		opacity: .6;
		z-index: 891;
	}
	.article.article-style-c .article-header {
		height: 216px;
	}
	.article .article-header {
		height: 155px;
	}
}
.chartsh {
    height: 16rem;
}
.stamp {
    color: #fff;
    background: #868e96;
    min-width: 3rem;
    height: 3rem;
    line-height: 3rem;
    text-align: center;
    border-radius: 100%;
    font-weight: 600;
	left:0;
	right:0;
}

.list-items {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
}
.revenue ul li .list-label {
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 100%;
    margin-right: 10px;
}
.progress{
	display: flex;
    height: 0.5rem;
    overflow: hidden;
    font-size: .75rem;
    background-color: #e9ecef;
    border-radius: .25rem;
}
/*---profile---*/
.profile .name {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
}
 .card .box .img{
	box-shadow: 0 13px 26px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.2);
}
.profile .title {
    font-size: .75em;
    font-weight: 300;
    text-align: center;
    padding-top: .5em;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}
.profile .social-icons {
    display: flex;
    margin:0 2px;
    justify-content: space-between;
}
.profile .social-icons .icon {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.profile .social-icons .icon h4 {
    color: #2f3245;
    font-size: 1.4em;
    margin-top: 1.0em;
    font-weight: 700;
    margin-bottom: .1em;
}
.profile .social-icons .icon:nth-of-type(1) a {
    background: linear-gradient(to bottom right, #5b6ed8, #3F51B5);
}
.profile .social-icons .icon a {
    color: #fff;
    width: 40px;
    height: 40px;
    font-size: 15px;
    line-height: 40px;
    text-align: center;
    border-radius: 30px;
    box-shadow: 0 13px 26px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.1);
}
.profile .social-icons .icon:nth-of-type(2) a {
    background: linear-gradient(to bottom right, #F44336, #F44336);
}
.profile .social-icons .icon:nth-of-type(3) a {
    background: linear-gradient(to bottom right, #1197d4, #639FF9);
}
/*----profile-closed---*/

 .card .card-footer{
	border-bottom-left-radius:7px;
	border-bottom-right-radius:7px;
}
.text-white-transaprent{
	color:#fff;
	opacity:0.5;
}

.clearfix::after {
    display: block;
    clear: both;
    content: "";
}
.dot-label{
	width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 100%;
    margin-right: 10px;
}
.row-deck>.col, .row-deck>[class*='col-'] {
display: -ms-flexbox;
display: flex;
-ms-flex-align: stretch;
align-items: stretch;
}
.row-deck>.col .card, .row-deck>[class*='col-'] .card {
-ms-flex: 1 1 auto;
flex: 1 1 auto;
}
.colorinput {
	margin: 0;
	position: relative;
	cursor: pointer;
}
.colorinput-input {
	position: absolute;
	z-index: -1;
	opacity: 0;
}
.colorinput-color {
	display: inline-block;
	width: 1.75rem;
	height: 1.75rem;
	border-radius: 3px;
	margin:4px;
	border: 1px solid rgba(167, 180, 201,.3);
	color: #fff;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}
.colorinput-color:before {
	content: '';
	opacity: 0;
	position: absolute;
	top: .45rem;
    left: .45rem;
	height: 1.25rem;
	width: 1.25rem;
	transition: .3s opacity;
	background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E") no-repeat center center/50% 50%;
}
.colorinput-input:checked~.colorinput-color:before {
	opacity: 1;
}
.colorinput-input:focus~.colorinput-color {
	border-color: #467fcf;
	box-shadow: 0 0 0 2px rgba(5, 117, 230, 0.25);
}
.gutters-xs {
    margin-left: -0.25rem;
}

.Earnings i{
	font-size:23px;
}
.bg-light{
	background-color:#f1f6fc  !important;
	border-bottom-left-radius:7px;
	border-bottom-right-radius:7px;
}

@media (max-width: 768px) {
	.navbar .nav-link {
		padding-left: 10px !important;
        padding-right: 10px !important;
	}
	.navbar-home .header-brand-img{
		/* width: 70px; */
		height: 47px !important;
	}
}
@media (min-width: 768px) {
	.pending-payment-body{
		min-width: 500px !important;
	}
}	

.search-element i{
	color:#fff;
	font-size: 16px;
}
.search-element .form-control{
	color:#24273c;
}
.bg_gray{
	background:#f8f9fa !important;
}
.pro_img{
	width:100%;
}
a.bg-primary:focus, a.bg-primary:hover, button.bg-primary:focus, button.bg-primary:hover {
    background-color: #027ade !important;
}
a.bg-secondary:focus, a.bg-secondary:hover, button.bg-secondary:focus, button.bg-secondary:hover {
    background-color: #5319b1!important;
}
.message-box-last-content .btn {
    padding:5px 12px;
}

.skills .btn{
	box-shadow:none;
}
/*---login----*/

.form-control.is-valid, .was-validated .form-control:valid {
	background-image: none !important;
	border-color: transparent !important;
}

.login-submit-btn{
	color: #0055a6 !important;
	background-color: #fff !important;
}

.login-wrap{
	width:100%;
	margin:auto;
	/* margin-top: 100px; */
	max-width:370px;
	min-height:392px;
	position:relative;
	/* background:url(https://raw.githubusercontent.com/khadkamhn/day-01-login-form/master/img/bg.jpg) no-repeat center; */
	border-radius: 5%;
	box-shadow:0 12px 15px 0 rgba(0,0,0,.24),0 17px 50px 0 rgba(0,0,0,.19);
}
.login-html{
	width:100%;
	height:100%;
	position:absolute;
	padding:10%;
	background-color:#fff;
	border-radius: 5%
}
.login-html .sign-in-htm,
.login-html .sign-up-htm{
	top:0;
	left:0;
	right:0;
	bottom:0;
	position:absolute;
	transform:rotateY(180deg);
	backface-visibility:hidden;
	transition:all .4s linear;
}
.login-html .sign-in,
.login-html .sign-up,
.login-form .group .check{
	display:none;
}
.login-html .tab,
.login-form .group .label,
.login-form .group .button{
	text-transform:uppercase;
}
.login-html .tab{
	font-size:22px;
	margin-right:15px;
	padding-bottom:5px;
	margin:0 15px 10px 0;
	display:inline-block;
	border-bottom:2px solid transparent;
}
.login-html .sign-in:checked + .tab,
.login-html .sign-up:checked + .tab{
	color:#0055a6;
	border-color:#00a3db;
}
.login-form{
	min-height:345px;
	position:relative;
	perspective:1000px;
	transform-style:preserve-3d;
}
.login-form .group{
	margin-bottom:15px;
}
.login-form .group .label,
.login-form .group .input,
.login-form .group .button{
	width:100%;
	color:#0055a6;
	display:block;
}
.login-form .group .input,
.login-form .group .button{
	/* border:none; */
	padding:12px;
	font-size: 13px !important;
	border-radius:25px !important;
	background:#ccc;
}
.login-form .group input[data-type="password"]{
	text-security:circle;
	-webkit-text-security:circle;
}
.login-form .group .label{
	color:#777;
	font-size:12px;
}
.login-form .group .button{
	background:#0055a6 !important;
	border: none !important;
	color:#fff !important
}
.login-form .group label .icon{
	width:15px;
	height:15px;
	border-radius:2px;
	position:relative;
	display:inline-block;
	background:rgba(255,255,255,.1);
}
.login-form .group label .icon:before,
.login-form .group label .icon:after{
	content:'';
	width:10px;
	height:2px;
	background:#fff;
	position:absolute;
	transition:all .2s ease-in-out 0s;
}
.login-form .group label .icon:before{
	left:3px;
	width:5px;
	bottom:6px;
	transform:scale(0) rotate(0);
}
.login-form .group label .icon:after{
	top:6px;
	right:0;
	transform:scale(0) rotate(0);
}
.login-form .group .check:checked + label{
	color:#fff;
}
.login-form .group .check:checked + label .icon{
	background:#1161ee;
}
.login-form .group .check:checked + label .icon:before{
	transform:scale(1) rotate(45deg);
}
.login-form .group .check:checked + label .icon:after{
	transform:scale(1) rotate(-45deg);
}
.login-html .sign-in:checked + .tab + .sign-up + .tab + .login-form .sign-in-htm{
	transform:rotate(0);
}
.login-html .sign-up:checked + .tab + .login-form .sign-up-htm{
	transform:rotate(0);
}

.login-sec .card{
	box-shadow:none;
}

/* .pulse {
	display: block;
    position: absolute;
    padding: 1px;
    top: .6rem;
    right: 0.8rem;
    width: 0.5rem;
    font-size: 0.65rem !important;
    height: 0.5rem;
    border-radius: 50%;
    background: #f2b200;
    text-align: center;
    cursor: pointer;
    box-shadow: 0 0 0 rgba(250, 41, 46, 0.9);
    animation: pulse 2s infinite;
}
.pulse:hover {
  animation: none;
} */
/* @-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0.9);
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
} */
/* @keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0.9);
    box-shadow: 0 0 0 0 rgba(204,169,44, 0.7);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
      box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
      box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
} */

.form-horizontal label.control-label {
    margin-bottom: 20px;
}
.search-bar{
	position:relative;
}
@media (max-width: 630px){
	.filter-dropdown,.dashboard-filter__btn{
		width:100% !important
	}

}

.instrument-title{
	font-family:'MyriadProSemibold' !important;
	font-size:0.8rem !important;
	color:#0055a6;
	/* height: 33px;
	max-height: 40px; */
	overflow: hidden;
	text-overflow: ellipsis;
	/* TODO  addd whitespace*/
}
.item-html .instrument-title{
	min-height: 40px;
}
.new-order-desc-title{
	font-family:'MyriadProSemibold' !important;
	font-size:0.8rem !important;
	color:#0055a6;
}
.header-title{
	font-family:'MyriadProSemibold' !important;
	font-size:1rem !important;
	color:#0055a6;
}
.instrument-desc{
	font-family:'MyriadProRegular' !important;
	font-size:0.8rem !important;
	color:#777;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.instrument-desc-label{
	font-size:.8rem !important;
	color: #232323;
}

.instrument-detail-wrap .instrument-title{
	font-family: 'MyriadProSemibold' !important;
    font-size: .9rem !important;
    color: #0055a6;
	height: auto;
    max-height: inherit;
    min-height: inherit;
}

.instrument-detail-wrap .instrument-desc{
	font-family:'MyriadProRegular' !important;
	font-size:0.85rem !important;
	color:#777;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.instrument-detail-wrap .instrument-desc-label{
	font-size:.85rem !important;
	color: #232323;
}

.item-details-btn{
	font-size:12px !important
}

.instrument-action-dropdown.dropdown-menu.show{
	transform: translate3d(-106px, 32px, 0) !important;
}

@media (max-width:1200px){
	#root{
		min-height:100vh
	}
}
@media (max-width: 992px){
	.w-65{
		width: 140px !important;
	} 
	/* .page-content.dashboard-content.show-drawer{
		margin-left: 0 !important;
	}  */
	.page-sidebar.dashboard-sidebar.show-drawer{
		width: 230px !important;
	}
	.sidemenu-banner{
		/* width: 150px !important; */
		width: 100px !important;
		text-align: center;
    	margin: 0 auto;
	}
	.page-sidebar.dashboard-sidebar.show-drawer ul,.page-sidebar.dashboard-sidebar.show-drawer li{
		width: 230px !important
	}
	.page-sidebar.dashboard-sidebar.show-drawer{
		padding-top: 60px !important
	}
	.page-header.dashboard-header.show-drawer .main-navbar{
		margin-left: 0;
	}
	.page-header.dashboard-header.show-drawer .main-navbar,.page-content.dashboard-content.show-drawer,.page-footer-div.show-drawer{
		width: 100% !important;
		margin-left: 0 !important;
		transition: none;
	}
	.w-90{
		width: 80% !important;
	}
}

@media(max-width:767px){
	.page-header.dashboard-header.show-drawer .main-navbar,.page-content.dashboard-content.show-drawer{
		margin-left: 0;
		transition: none;
	}
	.page-sidebar.dashboard-sidebar.show-drawer{
		padding-top: 60px !important
	}
	.w-90{
		width: 50% !important;
	}
	
	.service-contract-detail-div{
		border: 1px solid #dee2e6 !important;
		border-width: 1px 0 0 0 !important;
		margin: 5px 0 !important;
	}
	.profile-avatar-placeholder{
		width: 60% !important;
	}
}

.sidemenu-banner{
	width: 13vw;
}

.rangeslider{
	background-color: #ccc !important;
}
.rangeslider-horizontal{
	height: 4px !important;
	border-radius: 0 !important;
}

/* .rangeslider-horizontal .rangeslider__fill {
    background-color: #6bb441!important;
} */

.rangeslider .rangeslider__handle{
	box-shadow: none !important ;
}

.rangeslider-horizontal .rangeslider__handle{
	width: 13px !important;
	height: 13px !important;
	border: 1px solid #777 !important;
	background: #fff !important;
	padding: 4px;
}
.rangeslider__labels .rangeslider__label-item{
	color: #777;
	font-weight: 500;
	font-size: 0.75rem !important;
}

.rangeslider__labels{
	width: 100% !important;
}
.rangeslider__labels .rangeslider__label-item:first-of-type{
	transform: none !important;
	right: 0;
    right: 1px !important;
	left: auto !important;
}
.rangeslider__labels .rangeslider__label-item:last-of-type{
	left: 30px !important;
}
.service-contract-detail-div .instrument-desc{
	overflow: auto;
    text-overflow: initial;
    white-space: initial;
}

.notification-container span{
	font-size: 14px !important;
} 

.sub-menu a{
	font-size: .75rem !important;
	color:#0055a6 !important;
	background-color: #e4f5fd !important;
}
.sub-menu{
	background-color: #e4f5fd !important;
}
.page-sidebar.dashboard-sidebar a,.page-sidebar.dashboard-sidebar.show-drawer a{
	padding: 10px 13px;
}

.new-order-desc-title{

}
.welcome-sub-title{
	color: #0055a6;
	font-size: .75rem !important;
}

@media (min-width:1400px){
	.px-xxl-5{
		padding-left: 2.83rem !important;
		padding-right: 2.83rem !important;
	}

	.intro-desc,.welcome-sub-title,.new-order-desc-title,.instrument-desc-label,.instrument-desc,.instrument-title,.instrument-desc-label u{
		font-size: .9rem !important;
	} 
	.sidemenu-banner{
		width: 10vw !important;
	}
	.sub-menu a{
		font-size: .85rem !important;
	}
	.new-order-div h2{
		font-size: 3rem !important;
		height: initial;
		color: #0055a6;
		font-family: 'MyriadProSemibold' !important;
	}

	.welcome-title,.header-form .header-title{
		font-size: 1.2rem !important;
	}
	.new-order-div .h2-span {
		color: #0055a6 !important;
		font-size: 1.4rem !important;
		font-weight: bold !important;
	}
	.dashboard-sidebar .side-menu__label{
		font-size: .9rem !important;
		letter-spacing: 0.01rem;
	}
	.dashboard-sidebar .side-menu__btn{
		font-size: .75rem !important;
	}
}
@media(min-width:768px){
	.service-contract-detail-div{
		border: 1px solid #dee2e6 !important;
		border-width: 0 0 0 1px !important;
		margin: 0  !important;
	}
	.sales-order-data{
		border-right: 1px solid #ccc;
	}
}

.order-items-table td, .order-items-table th{
	font-size: 12px !important;
}

@media screen and (max-height:625px){

	.notification-container span{
		font-size: 12px !important;
	} 
	
	.dashboard-sidebar .side-menu__icon{
		width: 16px !important;
	}  
	.dashboard-sidebar .side-menu__label{
		font-size: .7rem !important;
	}  
	
	.dashboard-sidebar .side-menu__btn{
		color: #0055a6;
		border-radius: 25px !important;
		font-size: 0.6rem;
	}

	.instrument-title{
		font-size: 0.75rem !important;
	}

	.instrument-desc-label, .instrument-desc{
		font-size: 0.7rem !important;
	}

	.item-details-btn{
		font-size: 11px !important;
	}

	.dashboard-filter__btn{
		font-size: 11px !important;
	}

	.filter-dropdown{
		font-size: 11px !important
	}

	.filter-label{
		font-size: 12px !important;
	}

	.header-search{
		font-size: 12px !important;
	}

	.user-dropdown{
		font-size: 12px !important;
	}

	.instrument-detail-wrap .instrument-title{
		font-size: 0.75rem !important;
	}

	.instrument-detail-wrap .instrument-desc-label{
		font-size: 0.7rem !important;
	}

	.instrument-detail-wrap .instrument-desc{
		font-size: 0.7rem !important;
	}
}

@media screen and (min-height:525px){
	/* #root,main{
		height: 100%;
		/* min-height: 100vh; 
	} */
	#root{
		height: 100%;
		/* min-height: 100vh; */
	}
}

.filter-input{
	font-size: 12px !important;
	height: 37px !important;
}

.widget-svg{
	width: 25px !important;
	height: 25px !important;
}

.header-form .instrument-title{
	font-size: 1rem !important;
}

.sweet-alert{
	width:320px !important;
	padding: 1rem !important;
}

.sweet-alert .btn-success{
	background-color: #0055a6 !important;
	padding: .75rem 1.35rem !important;
}

.sweet-alert .text-muted.lead p{
	margin-bottom: 0 !important;
	font-size: 0.9rem !important;
}

.profile-avatar-placeholder{
	width: 100%;
}

.page-footer-div{
	background-color: #0055a6;
	color: #fff;
	position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.page-footer-div.show-drawer{
	background-color: #0055a6;
	color: #fff;
	margin-left: 15vw;
}
.footer-copyright{
	font-family: 'MyriadProRegular';
	font-size: 0.8rem !important;
}

.page-content.component-loading main{
	padding-bottom: 50px;
}

.login-main{
	height: calc( 100vh - 150px );
}

.login-banner{
	height: 100%;
	background-image: url('../img/landingBg_1.jpg');
	background-repeat: no-repeat;
	background-size: 100% 100%;
}
.login-banner-text,.registration-banner-text{
	position: absolute;
	top: 0;
	left:0;
	bottom:0;
	right:0;
	z-index: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.login-banner-text p,.registration-banner-text p{
	text-align: center;
	font-weight: 400 !important;
	font-size: 0.9rem !important;
}
.login-banner-text h4{
	font-family: 'Advent Pro' !important;
	letter-spacing: 0.12rem !important;
}
.login-div a,.login-div label{
	font-weight: 400;
}
.login-submit-btn{
	color: #0055a6 !important;
	background-color: #fff !important;
}

.login-wrap{
	width: 100%;
    margin: 0 auto;
    max-width: 756px;
}

.login-wrap a{
	font-size: 0.7rem !important;
}
.login-wrap .sign-in-htm,
.login-wrap .sign-up-htm{
	top:0;
	left:0;
	right:0;
	bottom:0;
	backface-visibility:hidden;
	transition:all .4s linear;
}
.login-wrap .sign-in,
.login-wrap .sign-up,
.login-form .group .check{
	display:none;
}
.login-wrap .tab,
.login-form .group .label,
.login-form .group .button{
	text-transform:uppercase;
}
.login-wrap .tab{
    font-size: 20px !important;
    padding-left: 8px !important;
    margin-right: 15px;
    margin: 0 15px 10px 0;
    display: inline-block;
    border-radius: 2px;
    border-width: 2.5px !important;
    border-color: #00a3db;
}
.login-wrap .sign-in:checked + .tab,
.login-wrap .sign-up:checked + .tab{
	color:#0055a6;
	border-color:#00a3db;
}
.login-form{
	min-height:218px;
	position:relative;
	perspective:1000px;
	transform-style:preserve-3d;
}
.login-form .group{
	margin-bottom:15px;
}
.login-form .group .label,
.login-form .group .input,
.login-form .group .button{
	color:#0055a6;
	display:block;
}
.login-form .group .input{
	padding: 20px 12px !important;
	border: 1px solid rgba(39, 75, 160, 0.65);
}

.login-form .group .button{
	padding:12px;
}
.login-form .group input[data-type="password"]{
	text-security:circle;
	-webkit-text-security:circle;
}
.login-form .group .label{
	color:#777;
	font-size:.75rem;
	font-weight: 500;
}
.login-form .group .invalid-feedback{
	font-weight: 500;
}

.login-form .group .login-submit-btn{
	background:#0055a6 !important;
	border: none !important;
	color:#fff !important
}
.login-form .group .check:checked + label{
	color:#fff;
}
.login-wrap .sign-in:checked + .tab + .sign-up + .tab + .login-form .sign-in-htm{
	transform:rotate(0);
}

.slick-prev:before, .slick-next:before{
	color:#b6bbbd !important;
	font-size:30px !important
}
.slick-next,.slick-prev{
	z-index: 1 !important;
}
.slick-prev{
	left: 0 !important;
}

.slick-next{
	right: 12px !important;
}

/*PAGINATION STYLE*/
.cs-pagination {
	white-space: nowrap;
	padding: 5px;
	color: #303133;
	font-weight: bold;
	font-size: 1.875rem;
	text-align: right;
	list-style-type: none;
}
.cs-pagination_item {
	color: #606266;
	border-radius: 2px;
	vertical-align: top;
	display: inline-block;
	font-size: 13px;
	height: 28px;
	line-height: 28px;
	cursor: pointer;
	box-sizing: border-box;
	text-align: center;
	margin: 0 5px;
	padding: 0;
}
.cs-pagination_item a {
	padding: 0 8px;
	display: inline-block;
	color: inherit !important;
	min-width: 22px;
	background-color: #f8fafb;
	outline: none;
	border: 1px solid #274ba0;
	border-radius: 2px;
}
.cs-pagination_item.active a {
	background-color: #0055a6;
	color: #fff !important;
}
.cs-pagination .previous, .cs-pagination .next {
	color: #606266;
	/*border: 1px solid #274ba0;*/
	border-radius: 18px;
	vertical-align: top;
	font-size: .9rem;
	font-weight: normal;
	/* height: 28px; */
	line-height: 28px;
	cursor: pointer;
	box-sizing: border-box;
	text-align: center;
	display: inline-block;
	margin: 0 5px;
	padding: 0;
	background-color: #f8fafb;
}
.cs-pagination .previous a, .cs-pagination .next a {
	padding: 0 12px;
	display: inline-block;
	color: inherit !important;
	min-width: 22px;
	outline: none;
}
.cs-pagination .previous.disabled, .cs-pagination .next.disabled {
	color: #9eaec0 !important;
	cursor: not-allowed;
}
.pagination-arrow{
	font-size: 20px;
	color: #0055a6;
}
/*PAGINATION ENDS*/

/* Combobox START*/
.rw-combobox .rw-list-option{
	font-size:0.75rem;
}
.rw-combobox .rw-list{
	text-align: left;
}
/* Combobox ENDS*/

.infinite-scoll::-webkit-scrollbar {
	width: .4rem;
}
   
.infinite-scoll::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	border-radius: 5px;
}
   
.infinite-scoll::-webkit-scrollbar-thumb {
	background-color: darkgrey;
	outline: 1px solid slategrey;
	border-radius: 5px;
	opacity: .5;
}
.rw-i{
    font-family: 'RwWidgets' !important;
}

.material-no-cell{
	word-break: break-all;
}

.instrument-summary-slider .slick-dots{
	bottom: -10px;
}

.instrument-summary-slider .slick-dots li.slick-active button:before{
	color: #0055a6;
}

.instrument-summary-slider .slick-dots li button:before{
	font-size: 10px;
}
.instrument-summary-slider .slick-next:before{
	content: '\27A4';
}
.instrument-summary-slider .slick-prev:before{
	content: '\27A4';
	transform: rotate(-180deg);
}
.instrument-summary-slider .slick-arrow.slick-prev{
	transform: rotate(-180deg);
	display: block;
	margin-top: 4px;
}
.archive-tabs .nav-link{
	font-size:14px !important
}
.card-more-btn::before{
	/* border: 1px solid #0055a6; */
	font-size: 19px;
	border-radius: 50%;
	background-color: #0055a6
}
.instrument-card-dropdown-btn::after{
	display: none !important;
}
@media (max-width: 766px){
	.filter-width{
		margin-bottom: 6px !important;
		width: 100% !important;
	}
}
@media (min-width: 766px){
	.filter-width{
		width: 60% !important;
	}
}
@media (max-width: 736px){
	.filter-close-btn{
		top: 36px !important;
	}
}
.pulse {
  	animation: pulse-animation 2s infinite;
	display: inline-block;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0,0,0,0.42);
  }
  100% {
    box-shadow: 0 0 0 40px rgba(0,0,0,0);
  }
}

.active-indicator{
	position: absolute;
    width: 13px;
    height: 13px;
    top: -3px;
    border: .5px solid white;
    border-radius: 50%;
    background: orange;
    right: -6px;
}

	/* E-methods css Start*/
	.analysis-item-title{
		color: #0055a6;
	}

	.filter-dropdown::after{
		float: right;
		margin-top: 0.5em
	}
	.filter-dropdown{
		width: 75% !important;
	}
	.filter-dropdown .drop-down-item{
		color:#232323 !important
	}
	.filter-dropdown,.dashboard-filter__btn{
		width:100% !important
	}
	.filter-dropdown{
		font-size: 11px !important
	}
	#filterModal .modal-footer button{
		background-color: #0055a6;
	}
	.modal-footer {
		border-top:1px solid  #f0f0f2;
	}
	.custom-control-label {
		display: flex;
	}
	.custom-control-label::before {
		position: absolute;
		left: -1.5rem;
		display: block;
		width: 1rem;
		height: 1rem;
		pointer-events: none;
		content: "";
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		background-color: #dee2e6;
	}
.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: #3454f5;
}
#filterModal .accordion .custom-control-label{
	font-size: .75rem !important;
}
#filterModal .accordion .custom-control-label:before{
	width: .8rem;
	height: .8rem;
	top:.1rem !important
}
#filterModal .accordion .custom-control-label:after{
	top: 0 !important;
	left: -1.6rem !important;
}
#filterModal .accordion .fields-label{
	color: grey;
	font-size: .8rem;
}
#filterModal .accordion .fields-label{
	color: grey;
	font-size: .8rem;
}
.analysis-detail-content#content_Systems label{
	font-size: .8rem;
}
.analysis-detail-content td{
	color: #777 !important;
	font-weight: bold;
}
@media (max-width: 630px){
	.filter-dropdown,.dashboard-filter__btn{
		width:100% !important
	}
}
	/* E-methods css End*/
